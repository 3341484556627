body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black  url('/src/assets/chess.jpg');
  background-size: cover;
  color: white;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 320px) and (orientation: portrait) {
  html {
    margin: auto;
    transform: rotate(-90deg);
    transform-origin: left top;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: -10px;
    height: 100vw;
    width: 100vh;
  }
}