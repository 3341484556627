.gameDuration {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gameDuration ul {
  display: flex;
  list-style: none;
  justify-content: left;
  margin: 30px 60px;
  flex-wrap: wrap;
}

.duration {
  color: white;
  background-color: rgb(78, 48, 48);
  min-width: 60px;
  margin: 5px;
  padding: 5px;

  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(0, 0, 0, 0.001);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35), 0px 1px 3px rgba(0, 0, 0, 0.18),
    inset 0px -3px 0px rgba(0, 0, 0, 0.35),
    inset 0px 14px 14px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.35),
    0px 1px 3px rgba(0, 0, 0, 0.18), inset 0px -3px 0px rgba(0, 0, 0, 0.35),
    inset 0px 14px 14px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.35), 0px 1px 3px rgba(0, 0, 0, 0.18),
    inset 0px -3px 0px rgba(0, 0, 0, 0.35),
    inset 0px 14px 14px rgba(255, 255, 255, 0.1);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.35), 0px 1px 3px rgba(0, 0, 0, 0.18),
    inset 0px -3px 0px rgba(0, 0, 0, 0.35),
    inset 0px 14px 14px rgba(255, 255, 255, 0.1);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.35), 0px 1px 3px rgba(0, 0, 0, 0.18),
    inset 0px -3px 0px rgba(0, 0, 0, 0.35),
    inset 0px 14px 14px rgba(255, 255, 255, 0.1);
}

.duration:active {
  border-radius: 2px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17512156);
}

.durationSetter {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(184, 180, 180, 0.082);
  padding: 10px;
}

.durationSetter input{
    width: 100px;
    height: 20px;
    margin: 5px;
}
